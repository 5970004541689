
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "ember-cookies/-embroider-implicit-modules.js";
import "@simplepractice/ember-messaging-client/-embroider-implicit-modules.js";
import "ember-animated/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-cli-mirage/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-power-calendar-moment/-embroider-implicit-modules.js";
import "ember-simple-auth/-embroider-implicit-modules.js";
import "ember-simplepractice/-embroider-implicit-modules.js";
import "ember-sinon-qunit/-embroider-implicit-modules.js";
import "ember-sortable/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "ember-cli-fastboot/-embroider-implicit-modules.js";
import "@ember/jquery/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "ember-cli-code-coverage/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "@percy/ember/-embroider-implicit-modules.js";
import "ember-cli-string-helpers/-embroider-implicit-modules.js";
import "ember-composable-helpers/-embroider-implicit-modules.js";
import "ember-infinity/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-set-helper/-embroider-implicit-modules.js";
import "ember-basic-dropdown/-embroider-implicit-modules.js";
import "ember-svg-jar/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-cli-clean-css/-embroider-implicit-modules.js";
import "ember-cli-content-for-config/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-cli-dependency-lint/-embroider-implicit-modules.js";
import "ember-cli-deploy/-embroider-implicit-modules.js";
import "ember-cli-deploy-cloudfront/-embroider-implicit-modules.js";
import "ember-cli-deploy-lightning-pack/-embroider-implicit-modules.js";
import "ember-cli-head/-embroider-implicit-modules.js";
import "ember-test-selectors/-embroider-implicit-modules.js";
import "ember-cli-inline-content/-embroider-implicit-modules.js";
import "ember-inline-css/-embroider-implicit-modules.js";
import "ember-cli-swiper/-embroider-implicit-modules.js";
import "ember-cli-terser/-embroider-implicit-modules.js";
import "ember-cli-sri/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-cp-validations/-embroider-implicit-modules.js";
import "ember-data-model-fragments/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
import "ember-decorators/-embroider-implicit-modules.js";
import "ember-diff-attrs/-embroider-implicit-modules.js";
import "ember-exam/-embroider-implicit-modules.js";
import "ember-fetch/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-power-calendar/-embroider-implicit-modules.js";
import "ember-resolver/-embroider-implicit-modules.js";
import "ember-responsive/-embroider-implicit-modules.js";
import "ember-route-action-helper/-embroider-implicit-modules.js";
import "ember-router-scroll/-embroider-implicit-modules.js";
import "ember-set-body-class/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
import "qunit-dom/-embroider-implicit-modules.js";
import "ember-cli-form-data/-embroider-implicit-modules.js";
import "ember-ref-bucket/-embroider-implicit-modules.js";
