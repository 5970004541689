
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "ember-cli-mirage/-embroider-implicit-modules.js";
import "ember-concurrency/-embroider-implicit-modules.js";
import "ember-inflector/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-simplepractice/-embroider-implicit-modules.js";
import "ember-truth-helpers/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "@ember/test-waiters/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "@ember-data/adapter/-embroider-implicit-modules.js";
import "@ember-data/model/-embroider-implicit-modules.js";
import "@ember-data/serializer/-embroider-implicit-modules.js";
import "ember-cli-string-helpers/-embroider-implicit-modules.js";
import "ember-composable-helpers/-embroider-implicit-modules.js";
import "ember-get-config/-embroider-implicit-modules.js";
import "ember-in-viewport/-embroider-implicit-modules.js";
import "ember-infinity/-embroider-implicit-modules.js";
import "ember-intl/-embroider-implicit-modules.js";
import "ember-set-helper/-embroider-implicit-modules.js";
import "ember-svg-jar/-embroider-implicit-modules.js";
